import { Component, createResource } from 'solid-js'
import { SubmitHandler, createForm, setError, valiForm } from '@modular-forms/solid'
import Button from '~/components/button/button'
import styles from '~/components/forms/styles/complex-form.module.scss'
import FormError from '~/components/form-error/form-error'
import { useNavigate } from '@solidjs/router'
import { PostgrestError } from '@supabase/supabase-js'
import ImagesUploadField from '~/components/images-upload-field/images-upload-field'
import { ChapterUploadFormProps, ChapterUploadFormType, ChapterUploadSchema } from '~/components/forms/chapter-upload/chapter-upload.interfaces'
import { createPages } from '~/services/database/pages'
import { translations } from '~/translations'
import { getSeries } from '~/services/database/series'

const ChapterUploadForm: Component<ChapterUploadFormProps> = ({ chapter }) => {
  const [series] = createResource(chapter.series_id, getSeries)
  const chapterName = `${chapter.index} ${chapter.title}`
  const navigate = useNavigate()

  const [form, { Form, Field }] = createForm<ChapterUploadFormType>({
    validate: valiForm(ChapterUploadSchema),
  })

  const onSubmit: SubmitHandler<ChapterUploadFormType> = async (values) => {
    const payload = {
      ...values,
      chapterId: chapter.id,
      type: 'clean'
    }
    await createPages(payload).then(() => {
      navigate(`/series/chapter/${chapter.id}/typesetting`)
    }).catch(error => {
      const message = (error as PostgrestError).message
      setError(form, 'files', message)
    })
  }

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <div class={styles.head}>
        <div class={styles.primaryTitle}>
          {translations().series.series}
        </div>
        <div class={styles.secondaryTitle}>
          {series.latest?.title}
        </div>
      </div>
      <div class={styles.head}>
        <div class={styles.primaryTitle}>
          {translations().chapters.row.chapter} {chapterName}
        </div>
        <div class={styles.secondaryTitle}>
          {translations().chapters.forms.create.targetPages}
        </div>
      </div>
      <div class={styles.fieldColumn}>
        <Field name="files" type="File[]">
          {(field, props) => (
            <ImagesUploadField
              {...field}
              {...props}
            />
          )}
        </Field>
        <div class={styles.fileUploadDescription}>
          <p>{translations().chapters.forms.create.guidelines1}</p>
          <ul>
            <li>series_title_chapter14_001.jpg</li>
            <li>series_title_chapter14_002.jpg</li>
            <li>series_title_chapter14_003.jpg</li>
          </ul>
          <p>{translations().chapters.forms.create.guidelines2} : 8192px</p>
        </div>
        <FormError response={form.response} />
        <Button
          type='submit'
          style='lagoon'
          isLoading={form.submitting}
        >
          {translations().general.actions.send}
        </Button>
      </div>
    </Form>
  )
}

export default ChapterUploadForm